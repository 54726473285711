<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo">Notificações</h1>
      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            v-model="itensSelect"
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            :single-select="singleSelect"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage">
            <template v-slot:top>
              <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
            </template>
              <template v-slot:top>
                <!-- Select tipo de entidade -->
                <BrToolBar @search="buscar" :configFilter="{ listaSelect: listKeys, getItens: getItens, jsonData: true }" :select="listaTipoBusca">
                  <!-- <template v-slot:btnSelect>
                    <v-col class="d-flex justify-end align-center">
                      <v-menu
                        transition="slide-y-transition"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="purple"
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            min-width="150px"
                          >
                            {{ typeNotification }}
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item @click="selectTypeNotification('TODOS')">
                            <v-list-item-title>TODOS</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="selectTypeNotification('PORTAL')">
                            <v-list-item-title>PORTAL</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="selectTypeNotification('PORTAL-IND')">
                            <v-list-item-title>PORTAL-IND</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="selectTypeNotification('WHATSAPP')">
                            <v-list-item-title>WHATSAPP</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </template> -->
                </BrToolBar>
              </template>
             

              <template v-slot:item.sentMessages="{ item }">
                <v-chip  :color="getColorSituacao(item.sentMessages[0])" dark>{{ textStatus(item.sentMessages[0])  }}</v-chip>
              </template>
              <template v-slot:item.message.jsonData.image="{ item }">
                <v-img width="70px" v-if="item.message.jsonData.image" :src="`${config.urlAWS}${item.message.jsonData.image}`" aspect-ratio="2.5"></v-img>
              </template>

              <template v-slot:item.action="{ item }">
                <v-btn
                  v-if="permiteAcao($route, 'edit')"
                  small
                  @click="editItem(item)"
                  tile
                  outlined 
                  :color="variables.colorPrimary"
                  class="mr-2"
                >
                  Editar
                </v-btn>

                <v-btn
                  v-if="permiteAcao($route, 'delete')"
                  small
                  @click="deleteItem(item)"
                  tile
                  outlined 
                  :color="variables.colorError"
                >
                  Excluir
                </v-btn>
              </template>

              <template v-slot:footer>
                <div class="wrapper-footer">
                  <div class="qtd-paginacao">
                    {{ pageStart }}
                    -
                    {{ pageStop }}
                    de
                    {{ totalItens }}
                  </div>
                  <v-pagination
                    v-model="page"
                    :length="numberOfPages"
                    circle
                    :disabled="disabled"
                    :total-visible="7"
                    class="box-table-paginacao"
                    v-if="numberOfPages > 1"></v-pagination>
                  <!-- <v-btn color="primary" @click="modalOpen()" class="br-btn"> Enviar sms</v-btn> -->
                </div>
              </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import listagemTables from '@/views/mixins/listagemTables'
import { map, size } from 'lodash'
import config from '@/core/config'

export default {
  name: 'EnvioSMS',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    singleSelect: false,
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Título', value: 'message.jsonData.title' },
      { align: 'start', class: 'table-header', text: 'Imagem', value: 'message.jsonData.image' },
      { align: 'start', class: 'table-header', text: 'Canal', value: 'channel.name' },
      { align: 'end', class: 'table-header', text: 'Status', value: 'sentMessages', sortable: false },
    ],
    tipoBusca: 6,
    listaTipoBusca: [
      {text: 'ATS', value: 1 },
      {text: 'OSC ', value: 2 },
      {text: 'CREDENCIADOR ', value: 3 },
      {text: 'ESTABELECIMENTO ', value: 4 },
      {text: 'CAIXA ', value: 5 },
      {text: 'USUARIO WEB ', value: 6 },
      {text: 'INFLUENCIADOR', value: 7 }
    ],
    typeBusca: 'entities',
    itensSelect: [],
    typeNotification: 'TODOS'
  }),
  computed: {
    ...mapGetters('notificacao', ['listaItens', 'totalItemSelect']),
    listKeys () {
      return { name: '', telefone: ''}
    },
    totalItens () {
      return this.totalItemSelect || 0
    },

    config: () => config
  },
  created () {
    // this.filtroEntities()
    // this.getItens()
  },
  mounted () {
    const vm = this
    Events.$on('sms:clearTable', () => {
      console.log('chegou aqui')
      vm.itensSelect = [] })
  },
  watch: {
    tipoBusca (v) {
      this.addFiltroAtual(v).then(() => this.filtroEntities(v) )
    },
    itensSelect (val) {
      this.addDadosTable(this.envioSms(val))
    }
  },
  methods: {
    ...mapActions('notificacao', { getItens: 'getItens', getItensEntities: 'obterItens' }),

    modalOpen () { Events.$emit('Daodos::modal::sms') },

    filtroEntities (v = false) {
      const vm = this
      this.getItensEntities({
        pageSize: this.$store.getters.ItensPaginacao,
        _filter: { "entityTypeId_In":`${ !v ? this.tipoBusca : v}` }
      }).then(() => {
        vm.loadingTable = false
      })
      
    },
    envioSms () {
      let {itensSelect} = this
      return size(itensSelect) !== 0 ? map(itensSelect, v => ({numero: v.phone}) ) : []
    },

    selectTypeNotification (val) {
      this.typeNotification = val
    },

    getColorSituacao (status) {
      if (status && status.sended === false) return this.variables.colorError
      if (status && status.sended === true) return this.variables.colorSecondary
      return this.variables.colorPrimary
    },

    textStatus (status) {
      if (status && status.sended === false) return 'Não Enviado'
      if (status && status.sended === true) return 'Enviado'
      return 'Novo'
    }
  }
}
</script>

<style lang="scss">
  @import '../../../assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }
  // .filtro-entities{
  //   position: absolute;
  //   z-index: 1;
  //   top: -16.5px;
  //   @media(max-width: 600px){
  //     position: relative;
  //     width: 100% !important;
  //   }
  // }
</style>>
